
import './App.css';
import React,{Suspense} from "react" 
import Spinner from 'react-bootstrap/Spinner';
import { Route, Routes } from 'react-router-dom';

const Header=React.lazy(()=> import('./components/Header'))
const Home=React.lazy(()=> import('./components/Home'))
const Register=React.lazy(()=> import('./components/Register'))
const Vendor=React.lazy(()=> import('./components/Vendor'))
const Customer=React.lazy(()=> import('./components/Customer'))
const Welcome=React.lazy(()=> import('./components/Welcome'))
const Dashboardc=React.lazy(()=> import('./components/Dashboardc'))
const Verify=React.lazy(()=> import('./components/Verify'))
const Signout=React.lazy(()=> import('./components/Signout'))
const Myvendor=React.lazy(()=> import('./components/Myvendor'))
const Companydetails=React.lazy(()=> import('./components/Companydetails'))
const Bankdetails=React.lazy(()=> import('./components/Bankdetails'))
const Vdashbord=React.lazy(()=> import('./components/Vdashbord'))
const Index=React.lazy(()=> import('./components/Index'))
const Login=React.lazy(()=> import('./components/Login'))
const Market=React.lazy(()=> import('./components/Market'))
const Masterfile=React.lazy(()=> import('./components/Masterfile'))
const Marketreport=React.lazy(()=> import('./components/Marketreport')) //market report
const Mktprice=React.lazy(()=> import('./components/Mktprice')) 
const Addstaff=React.lazy(()=> import('./components/Addstaff')) 
const Pricemodel=React.lazy(()=> import('./components/Pricemodel')) 
const Verifypayment=React.lazy(()=> import('./components/Verifypayment')) 
const Redirectf=React.lazy(()=> import('./components/Redirectf')) 
const Mydaily=React.lazy(()=> import('./components/Mydaily')) 
const Mymarketreport=React.lazy(()=> import('./components/Mymarketreport')) 
const Addmarket=React.lazy(()=> import('./components/Addmarket')) 
const Addstatemanager=React.lazy(()=> import('./components/Addstatemanager')) 
const Forgotpassword=React.lazy(()=> import('./components/Forgotpassword')) 
const Resetform=React.lazy(()=> import('./components/Resetform')) 
const Dailymarket=React.lazy(()=> import('./components/Dailymarket')) 
const Ratingcomment=React.lazy(()=> import('./components/Ratingcomment')) 
const Additem=React.lazy(()=> import('./components/Additem')) 
const Redirect=React.lazy(()=> import('./components/Redirect')) 
const Newmarketupdate=React.lazy(()=> import('./components/Newmarketupdate')) 
const Footer=React.lazy(()=> import('./components/Footer')) 
const Contactus=React.lazy(()=> import('./components/Contactus')) 
const Listthedate=React.lazy(()=> import('./components/Listthedate')) 
const Pmarket=React.lazy(()=> import('./components/Pmarket')) 
function App() {
  return (
    <div className="App">
<Routes>
<Route path="/home"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Home /></Suspense>}
    
     /> 



<Route path="/listthedate/:id"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Listthedate /></Suspense>}
    
     /> 

<Route path="/contactus"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Contactus /></Suspense>}
    
     /> 


   <Route path="/newmarketupdate"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Newmarketupdate /></Suspense>}
    
     />
  

     <Route path="/redirect"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Redirect /></Suspense>}
        
         />

<Route path="/additem"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Additem /></Suspense>}
    
     />


<Route path="/ratingcomment"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Ratingcomment /></Suspense>}
    
     />
<Route path="/dailymarket"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Dailymarket /></Suspense>}
    
     />

<Route path="/resetform"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Resetform /></Suspense>}
    
     />
<Route path="/addstatemanager"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Addstatemanager /></Suspense>}
    
     />


<Route path="/addmarket"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Addmarket /></Suspense>}
    
     />

<Route path="/mydaily"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Mydaily /></Suspense>}
    
     />


<Route path="/mymarketreport"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Mymarketreport /></Suspense>}
    
     />


<Route path="/verifypay"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Verifypayment /></Suspense>}
    
     />
<Route path="/pricemodel"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Pricemodel /></Suspense>}
    
     />


<Route path="/addstaff"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Addstaff /></Suspense>}
        
         />
<Route path="/mktprice/:id/:timeid"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Mktprice /></Suspense>}
        
         />



   {/* market report file */}
<Route path="/marketreport"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Marketreport /></Suspense>}
    
     />

<Route path="/masterfile"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Masterfile /></Suspense>}
    
     />
<Route path="/market/:id"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Market /></Suspense>}
    
     />




<Route path="/Pmarket/:id/:dayName"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Pmarket /></Suspense>}
    
     />
<Route path="/redirectf/:id"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Redirectf /></Suspense>}
    
     />

<Route path="/login"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Login /></Suspense>}
        
         />



<Route path="/forgotpassword"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Forgotpassword /></Suspense>}
        
         />

<Route path="/"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Index /></Suspense>}
    
     />



<Route path="/companydetails/:id"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Companydetails /></Suspense>}
    
     />
<Route path="/myvendor"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Myvendor /></Suspense>}
    
     />






<Route path="/bankdetails"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Bankdetails /></Suspense>}
    
     />



<Route path="/vdashbord"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Vdashbord /></Suspense>}
    
     />
<Route path="/verify"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Verify /></Suspense>}
    
     />




<Route path="/signout"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Signout /></Suspense>}
    
     />


<Route path="/dashboardc"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Dashboardc /></Suspense>}
        
         />


<Route path="/welcome"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Welcome /></Suspense>}
        
         />

<Route path="/customer"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
<Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>} ><Customer /></Suspense>}
    
     />
<Route path="/register"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Register /></Suspense>}
        
         />

<Route path="/vendor"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Vendor /></Suspense>}
        
         />
    

</Routes>

{/* <Footer/> */}
    </div>
  );
}

export default App;
